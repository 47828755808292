import React from "react"
import SinglePageHeader from "../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function ICHPhilosophy() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader
      title={`ICH ${t("philosophy")}`}
      bg="/assets/img/philosophy-banner.jpg"
    >
      <div className="container pd-top-100 pd-bottom-100">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title section-title-2 text-center">
              <h6 className="sub-title">{`ICH ${t("philosophy")}`}</h6>
              <h2 className="title">{t("philosophy-title-1")}</h2>
            </div>
          </div>
        </div>
        <ul>
          <li>{t("ich-philosophy-1-1")}</li>
          <li>{t("ich-philosophy-1-2")}</li>
          <li>{t("ich-philosophy-1-3")}</li>
          <li>{t("ich-philosophy-1-4")}</li>
          <li>{t("ich-philosophy-1-5")}</li>
          <li>{t("ich-philosophy-1-6")}</li>
          <li>{t("ich-philosophy-1-7")}</li>
          <li>{t("ich-philosophy-1-8")}</li>
          <li>{t("ich-philosophy-1-9")}</li>
          <li>{t("ich-philosophy-1-10")}</li>
        </ul>
      </div>
      <section className="ich-wrapper bg-grey">
        <div className="container pd-top-100 pd-bottom-100">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h6 className="sub-title">{`ICH ${t("philosophy")}`}</h6>
                <h2 className="title">{t("philosophy-title-2")}</h2>
              </div>
            </div>
          </div>
          <ul>
            <li>{t("ich-philosophy-2-1")}</li>
            <li>{t("ich-philosophy-2-2")}</li>
            <li>{t("ich-philosophy-2-3")}</li>
            <li>{t("ich-philosophy-2-4")}</li>
            <li>{t("ich-philosophy-2-5")}</li>
            <li>{t("ich-philosophy-2-6")}</li>
            <li>{t("ich-philosophy-2-7")}</li>
          </ul>
        </div>
      </section>
      <div className="container pd-top-100 pd-bottom-100">
        <div className="row">
          <div className="col-lg-12" style={{ fontSize: 20 }}>
            <blockquote style={{ fontStyle: "italic" }}>
              "{t("philosophy-final-word")}"
            </blockquote>
            <span style={{ float: "right" }}>-Danny Toe</span>
          </div>
        </div>
        <div className="row" style={{ paddingTop: 30 }}>
          <div className="col-lg-12">
            <a
              href="/assets/files/Investing.by.ICH.30.Dec.2020.pdf"
              className="pink-button"
              download
            >
              {t("download-guide-to-investing")}
            </a>
          </div>
        </div>
      </div>
    </SinglePageHeader>
  )
}
